<template>
  <div class="page-contact">
    <div class="contact-box">
      <div class="caption">{{ hq.name }}</div>
      <div class="sections">
        <ContactDl :methods="hq.contact.methods" class="def-grid dd-intact"/>
        <dl class="def-grid dd-intact">
          <dt>IČ</dt>
          <dd>24274607</dd>
          <dt>DIČ</dt>
          <dd>CZ24274607</dd>
        </dl>
      </div>
    </div>

    <ContactLocation title="Sídlo" :address="hq.address" :map-location="hq.mapLocation"
                     :address-overlay="hqAddressOverlay"/>
    <ContactLocation title="Provozovna" :address="branch.address" :map-location="branch.mapLocation"
                     :address-overlay="branchAddressOverlay"/>

    <div class="contact-form-wrapper">
      <h2>{{ t('contact.form') }}</h2>
      <ContactForm @submitted="submitForm" :locale="locale"/>
      <p v-if="contactFeedback" class="m-0">{{ contactFeedback }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import companyStore from "../../Directory/companyStore";
import ContactLocation from "../components/ContactLocation.vue";
import ContactForm from "../components/ContactForm.vue";
import {useTranslator} from "@/modules/App/i18n"
import ContactDl from "../components/ContactDl";

export default defineComponent({
  components: {
    ContactForm,
    ContactLocation,
    ContactDl
},
  props: {
    locale: String,
  },
  setup(props) {
    const t = useTranslator()

    const addressNoCountry = (address: any) => ({...address, country: undefined})

    const hqAddressOverlay = computed(() => addressNoCountry(companyStore.headquarters.value.address))
    const branchAddressOverlay = computed(() => addressNoCountry(companyStore.branch.value.address))
    const contactFeedback = ref('')

    return {
      hq: companyStore.headquarters,
      hqAddressOverlay,
      branch: companyStore.branch,
      branchAddressOverlay,
      contactFeedback,

      t,

      submitForm(values: any) {
        console.log(values)
        values.locale = props.locale

        const url = new URL(window.location.toString())
        url.pathname = '/contact-us.php'

        fetch(url.toString(), {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            'Content-Type': "application/json",
          },
        })
            .then((response) => response.json())
            .then((result) => result.status === 'ok')
            .catch(() => false)
            .then((success) => {
              contactFeedback.value = success ? t('contactForm.thank') : t('contactForm.oops')
            })
      }
    }
  },
})
</script>

<style lang="scss">
@import "../sass/mixins/breakpoints";

.page-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1em;

  .contact-box {
    background: var(--neutral-200);
    padding: 1em;

    &:first-child {
      width: 100%;
    }
  }

  .contact-location {
    flex: 1;
    min-width: 320px;

    @include sm() {
      min-width: 100%;
    }

    background: var(--neutral-200);

    .title, > .address {
      margin: 0.5em 1em;
    }

    .title {
      margin-bottom: 0.25em;
    }

    > .address {
      margin-top: 0.25em;
    }
  }

  .contact-form-wrapper {
    flex: 1;

    padding: 1em;
    background: var(--neutral-200);
    color: var(--neutral-900);
  }

  h2 {
    margin-top: 0;
  }

  textarea {
    min-height: 4em;
  }
}

.contact-box {
  .caption {
    font-weight: bold;
    font-size: 1.2em;
    margin-block-end: 0.5em;
  }

  .sections {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2em;

    > * {
      flex: 1;
    }
  }
}
</style>
