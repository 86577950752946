<template>
  <form @submit.prevent="submitContactForm" class="contact-form">
    <label for="author-name">{{ t('contactForm.name') }}<sup class="req">*</sup></label>
    <input v-model="message.author.name" required id="author-name">

    <label for="company">{{ t('contactForm.company') }}</label>
    <input v-model="message.author.company" id="company">

    <label for="email">{{ t('contactForm.mail') }}<sup class="req">*</sup></label>
    <input type="email" v-model="message.author.mail" required id="email">

    <label for="message">{{ t('contactForm.text') }}<sup class="req">*</sup></label>
    <textarea v-model="message.text" required id="message"></textarea>

    <button type="submit" class="btn btn-pill">{{ t('contactForm.submit') }}</button>
  </form>
</template>

<script lang="ts">
import {defineComponent, reactive} from "vue";
import {useTranslator} from "@/modules/App/i18n"

export default defineComponent({
  emits: {
    submitted: (msg: any) => !!msg && msg.author.name && msg.author.mail && msg.text,
  },
  props: {
    locale: String,
  },
  setup(props, options) {
    const t = useTranslator()

    const message = reactive({
      author: {
        name: '',
        mail: '',
        company: '',
      },

      text: '',
    })

    const submitContactForm = (e: Event) => {
      let formEl = e.target as HTMLFormElement

      let allValid = true
      for (let i = 0; i < formEl.elements.length; i++) {
        const el = formEl.elements[i]
        if (!(el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement)) {
          continue
        }
        allValid = allValid && el.validity.valid
      }

      if (allValid) {
        options.emit('submitted', message)
      }
    }

    return {
      submitContactForm,
      message,

      t,
    }
  },
})
</script>

<style lang="scss">
.contact-form {
  display: grid;
  grid-template-columns: minmax(auto, 20%) 1fr;
  gap: 0.5em 1em;

  p {
    margin: 0
  }

  button {
    grid-column: span 2;
    place-self: center;
    margin-top: 1em;
    font-size: 1em;
  }

  textarea {
    display: block;
    width: 100%;
    resize: vertical;
  }

  input, textarea {
    background: var(--neutral-900);
    color: var(--neutral-200);

    border: 0;
    border-bottom: 2px dotted var(--neutral-900);
    font-size: 1em;
  }
}
</style>
