
import {computed, defineComponent, ref} from "vue";
import companyStore from "../../Directory/companyStore";
import ContactLocation from "../components/ContactLocation.vue";
import ContactForm from "../components/ContactForm.vue";
import {useTranslator} from "@/modules/App/i18n"
import ContactDl from "../components/ContactDl";

export default defineComponent({
  components: {
    ContactForm,
    ContactLocation,
    ContactDl
},
  props: {
    locale: String,
  },
  setup(props) {
    const t = useTranslator()

    const addressNoCountry = (address: any) => ({...address, country: undefined})

    const hqAddressOverlay = computed(() => addressNoCountry(companyStore.headquarters.value.address))
    const branchAddressOverlay = computed(() => addressNoCountry(companyStore.branch.value.address))
    const contactFeedback = ref('')

    return {
      hq: companyStore.headquarters,
      hqAddressOverlay,
      branch: companyStore.branch,
      branchAddressOverlay,
      contactFeedback,

      t,

      submitForm(values: any) {
        console.log(values)
        values.locale = props.locale

        const url = new URL(window.location.toString())
        url.pathname = '/contact-us.php'

        fetch(url.toString(), {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            'Content-Type': "application/json",
          },
        })
            .then((response) => response.json())
            .then((result) => result.status === 'ok')
            .catch(() => false)
            .then((success) => {
              contactFeedback.value = success ? t('contactForm.thank') : t('contactForm.oops')
            })
      }
    }
  },
})
