
import {defineComponent, reactive} from "vue";
import {useTranslator} from "@/modules/App/i18n"

export default defineComponent({
  emits: {
    submitted: (msg: any) => !!msg && msg.author.name && msg.author.mail && msg.text,
  },
  props: {
    locale: String,
  },
  setup(props, options) {
    const t = useTranslator()

    const message = reactive({
      author: {
        name: '',
        mail: '',
        company: '',
      },

      text: '',
    })

    const submitContactForm = (e: Event) => {
      let formEl = e.target as HTMLFormElement

      let allValid = true
      for (let i = 0; i < formEl.elements.length; i++) {
        const el = formEl.elements[i]
        if (!(el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement)) {
          continue
        }
        allValid = allValid && el.validity.valid
      }

      if (allValid) {
        options.emit('submitted', message)
      }
    }

    return {
      submitContactForm,
      message,

      t,
    }
  },
})
